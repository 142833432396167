// import { useState, useEffect } from "react";
// import { collection, query, where, getDocs } from "firebase/firestore";
// import { db } from "../firebase";

// const useCategories = (mainCat) => {
//   //catName is Category name ex: altcogory, mainCategory
//   //catId is Category ex: Dresses
//   const [cats, setCats] = useState(null);
//   const [subCats, setSubCats] = useState(null);
//   const [altCats, setAltCats] = useState(null);

//   useEffect(() => {
//     const fetchPost = async () => {
//       let _posts = [];
//       const catName = mainCat;

//       console.log("Cat ID comes from hooks", catName)
//       try {
//         const citiesRef = collection(db, "categories");
//         const q = query(citiesRef, where("main", "==", catName));
//         const querySnapshot = await getDocs(q);
//         querySnapshot.forEach((doc) => {
//           _posts.push({ id: doc.id, ...doc.data() });
//         });
//         setCats(_posts);
//         subCatsFn(cats);
//         altCatsFn(cats)
//       } catch (error) {
//         console.log(error.message);
//       }
//     };
//     fetchPost();
//   }, [mainCat, cats]);

//   const subCatsFn = (cats) => {
//     let _items = [];
//     cats?.map((x) => _items.push(x["subCategory"]));
//     let uniqueChars = [...new Set(_items)];
//     setSubCats(uniqueChars);
//   };

//   const altCatsFn = (cats) => {
//     let _items = [];
//     cats?.filter((y)=>y["subCategory"] === "Accessories").map((x) => _items.push(x["title"]));
//     let uniqueChars = [...new Set(_items)];
//     setAltCats(uniqueChars);
//   };
//   return [cats, subCats,altCats];
// };

// export default useCategories;
import { useState, useEffect } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase";

const useCategories = (catID, subCat) => {
  const [datas, setData] = useState(null);
  const [subCats, setSubCats] = useState(null);
  const [altCats, setAltCats] = useState(null);
  const catName = catID;
  useEffect(() => {
    const fetchPost = async () => {
      let _posts = [];
      let _subCats = [];
      try {
        const citiesRef = collection(db, "categories");
        const q = query(citiesRef, where("main", "==", catName));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          _posts.push(doc.data());
          // _subCats.push(doc.data().subCategory)
          // _altCats.push(doc.data().title)
        });
        setData([...new Set(_posts)]);
        setSubCats([...new Set(_subCats)]);
        altCatsFn(_posts,subCat)
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchPost();
  }, [catName,subCat]);

  const altCatsFn = (datas,subCat) => {
    let _items = [];
    datas?.filter((y) => y.subCategory === subCat)
      .map((x) => _items.push(x["title"]));
    let uniqueChars = [...new Set(_items)];
    setAltCats([...new Set(uniqueChars)])
  };
  
  // useEffect(()=>{
  //   const altCatsFn = (datas,subCat) => {
  //     let _items = [];
  //     datas?.filter((y) => y["subCategory"] === subCat)
  //       .map((x) => _items.push(x["title"]));
  //     let uniqueChars = [...new Set(_items)];
  //     setAltCats(uniqueChars);
  //   };
   
  //   altCatsFn()
  // },[datas,subCat])
  
  return [datas,subCats,altCats ];
};

export default useCategories;
