import React, { useState, useEffect } from "react";
import Modal from "../modal/Modal";
import {
  addDoc,
  collection,
  doc,
  onSnapshot,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
const SizesComponent = () => {
  const [modalData, setModalData] = useState([]);
  const [size, setSize] = useState("");
  const [sizeList, setSizeList] = useState([]);
  const [err, setErr] = useState();
  const [success, setSuccess] = useState(false);
  const [show, setShow] = useState(false);

  //Input handling
  const handleChange = (e) => {
    e.preventDefault();
    setSize(e.target.value);
  };
  console.log(modalData)

  useEffect(() => {
    const unsub = onSnapshot(
      collection(db, "sizes"),
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setSizeList(list);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
  }, []);

  const handleSize = async (e) => {
    e.preventDefault();
    try {
      !(size === "") &&
        (await addDoc(collection(db, "sizes"), {
          size,
        }));
      setSuccess(true);
      setSize("");
      setTimeout(() => {
        setSuccess(false);
      }, 1000);
    } catch (error) {
      setErr(error.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      try {
        await deleteDoc(doc(db, "sizes", id));
        setSizeList(setSizeList.filter((item) => item.id !== id));
      } catch (error) {
        console.log(error);
      }
      // const res = await db.collection("cities").doc("DC").delete();
    } catch (error) {}
  };

  const handleEdit = async (id, updated) => {
    console.log("first", updated);
    try {
      const docRef = doc(db, "sizes", id);
      const data = {
        size: updated,
      };
      updateDoc(docRef, data);
      setShow(false);
    } catch (error) {
      console.log("Oops, Error happining during editing", +error);
    }
  };
  const showModal = async (b) => {
    setModalData(b);
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
  };
  return (
    <div className="sizesPage page">
      <div className="categoreisPageTop">
        <h1 className="pageTitle">Sizes</h1>
        <div className="btnOptions">
          <input
            type="text"
            placeholder=""
            onChange={handleChange}
            value={size}
          />
          <button onClick={handleSize}>Add a Brand</button>
          {success === true && <p className="successText">Size added</p>}

          {err && <p>{err}</p>}
        </div>
      </div>
      <div className="dataGrid">
        <table className="tableList">
          <thead>
            <tr>
              <th>#</th>

              <th>Size</th>
              <th colSpan={2} style={{ textAlign: "center" }}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {sizeList.map((b) => (
              <tr key={b.id}>
                <td>{sizeList.indexOf(b) + 1}</td>
                <td>{b.size}</td>
                <td>
                  <button
                    className="btn btnSmall btnEdit"
                    style={{ width: "100%" }}
                    /*</td>{/* onClick={() => handleEdit(b.id)}}*/
                    onClick={() => showModal(b)}
                  >
                    Edit
                  </button>
                </td>
                <td>
                  <button
                    className="btn btnSmall btnDelete"
                    style={{ width: "100%" }}
                    onClick={() => handleDelete(b.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Modal
        data={modalData}
        show={show}
        handleClose={hideModal}
        handleEdit={handleEdit}
      />
    </div>
  );
};

export default SizesComponent;
