import React, { useState, useEffect, useContext } from "react";
import "./add.css";
import uid from "../../utiles/uuid";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import useCategories from "../../utiles/useCategories";
import { AuthContext } from "../../contex/AuthContext";
import { words } from "../../translate";
let today = new Date();
let date =
  today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
let time =
  today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
let dateTime = date + " " + time;
const Add = () => {
  const [mainCategory, setMainCategory] = useState("mainCategory");
  const [subCategory, setSubCategory] = useState("subCategory");
  const [altCategory, setAltCategory] = useState("altCategory");
  const [brand, setBrand] = useState("");
  const [title, setTitle] = useState("");
  const [colors, setColors] = useState("");
  const [price, setPrice] = useState("");
  const [sizes, setSizes] = useState("");
  const [material, setMaterial] = useState("");
  const [detalis, setDetalis] = useState("");
  const [sizeAndFit, setSizeAndFit] = useState("");
  const [images, setImages] = useState("");
  const [isPublished, setIsPublished] = useState(false);
  const [inStock, setInStock] = useState(false);
  const [tags, setTags] = useState("");
  const [externalUrl, setExternalUrl] = useState("");
  const [itemNote, setItemNote] = useState("");
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [profit, setProfit] = useState(0);

  const [brandList, setBrandList] = useState([]);
  const [datas, subCats, altCats] = useCategories(mainCategory, subCategory);
  const { currentUser } = useContext(AuthContext);
  const [isTranslated, setIsTranslated] = useState("");
  const [fieldError, setFieldError] = useState(false);
  const [currentCatId, setCurrentCatId] = useState({ id: "", count: "" });
  const [aznToday, setAznToday] = useState(0);
  useEffect(() => {
    const unsub = onSnapshot(
      collection(db, "brands"),
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setBrandList(list);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
  }, []);

  //Clear form
  const clearForm = () => {
    window.location.reload(false);
  };

  //Validation
  const getIsFormValid = () => {
    return (
      altCategory !== "altCategory" &&
      deliveryFee >= 10 &&
      profit >= 10 &&
      externalUrl !== "" &&
      images &&
      brand &&
      material !== "" &&
      sizes &&
      price <= 240
    );
  };

  //Validation error msg
  const ErrorText = () => {
    return <span className="FieldError">Can`t be empty</span>;
  };

  //Get current altCategory id

  const currentAltCategoryId = async (mainCategory, altCategory) => {
    try {
      const q = query(
        collection(db, "categories"),
        where("main", "==", mainCategory),
        where("title", "==", altCategory)
      );
      let _locObj = [];
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, " => ", doc.data().count);
        _locObj.push(doc.id);
        _locObj.push(doc.data().count);
      });
      // setCurrentCatId({id:doc.id,{ ...password, isTouched: true }
      // count: doc.data(count())});
      setCurrentCatId(
        ...currentCatId,
        (currentCatId.id = _locObj[0]),
        (currentCatId.count = _locObj[1])
      );
    } catch (error) {
      console.log(error.code);
    }
  };
  currentAltCategoryId(mainCategory, altCategory);
  //Amigo Translator
  const amigo = (data, words) => {
    let str = `${data.material}`;
    let k = str.replaceAll(/\s/g, ",").split(",");
    for (const [key, value] of Object.entries(words)) {
      k.forEach((e, index) => {
        if (e === key) {
          k[index] = value;
        }
      });
    }
    let translatedStr = k.toString().replaceAll(",", " ");
    setIsTranslated(translatedStr);
  };

  //Creating Data object
  let _sizes = sizes?.replace(/\s/g, "").split(",");
  let _photos = images?.split(",");

  //Add new item
  const handleAdd = async (e) => {
    e.preventDefault();
    const _data = {
      mainCategory,
      subCategory,
      altCategory,
      brand,
      colors,
      sizes: [..._sizes],
      material,
      detalis,
      sizeAndFit,
      itemSKU: uid,
      images: [..._photos],
      isPublished,
      inStock,
      tags: tags,
      onSale: false,
      likes: 0,
      grossPrice: (Number(price) + Number(deliveryFee) + Number(profit))
        .toFixed(2)
        .toString(),
      createdAt: dateTime,
    };
    amigo(_data, words);
    try {
      await addDoc(collection(db, "products"), _data);
      await addDoc(collection(db, "userproducts"), {
        itemSKU: uid,
        price: Number(price),
        profit: profit,
        deliveryFee: deliveryFee,
        externalUrl,
        productOwner: currentUser.uid,
      });
      //       const washingtonRef = doc(db, "cities", "DC");

      // // // Set the "capital" field of the city 'DC'
      // await updateDoc(washingtonRef, {
      //   // count: currentCatId.count + 1
      // });
      alert("Item added");
      clearForm();
    } catch (error) {
      console.log(error.code);
      console.log(error.message);
    }
  };

  ///Get currency

  const getCurrency = async () => {
    try {
      const docRef = doc(db, "currency", "DJQHJzB11oqhhpakfxjp");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setAznToday(docSnap.data().aznToEur);
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  getCurrency();

  return (
    <div className="addPage">
      <h1>
        Add Item - <span>{uid}</span>
      </h1>
      <header></header>
      <div className="addPageWrapper">
        <div className="addPageWrapperHeader">
          <label>
            <span>Main Category</span>
            <select
              id="mainCategory"
              onChange={(e) => setMainCategory(e.target.value)}
              value={mainCategory}
            >
              <option defaultValue></option>
              <option value={"Women"}>Women</option>
              <option value={"Men"}>Men</option>
              <option value={"Kids"}>Kids</option>
            </select>
          </label>
          <label>
            <span>subCategory</span>
            <select
              id="subCategory"
              onChange={(e) => setSubCategory(e.target.value)}
              value={subCategory}
            >
              <option defaultValue></option>
              <option value={"Clothing"}>Clothing</option>
              <option value={"Accessories"}>Accessories</option>
              <option value={"Shoes"}>Shoes</option>
              <option value={"Beauty"}>Beauty & Care</option>
            </select>
          </label>
          <label>
            <span>altCategory</span>
            <select
              id="altCategory"
              onChange={(e) => setAltCategory(e.target.value)}
              value={altCategory}
            >
              <option value="altCategory">AltCategory</option>
              {altCats?.map((mc, i) => (
                <option key={i}>{mc}</option>
              ))}
            </select>
          </label>
        </div>
        <div className="addPageWrapperBody">
          {fieldError && <ErrorText />}
          <label>
            <span>Brend</span>
            <select onChange={(e) => setBrand(e.target.value)} value={brand}>
              <option value="brand">Select Brand</option>
              {brandList
                .sort((a, b) => a.brandName.localeCompare(b.brandName))
                .map((tm) => (
                  <option key={tm.id} value={tm.brandName}>
                    {tm.brandName}
                  </option>
                ))}
            </select>
          </label>
          <label>
            <span>Title</span>
            <input
              type="text"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            />
          </label>
          <label>
            <span>Colors</span>
            <input
              type="text"
              onChange={(e) => setColors(e.target.value)}
              value={colors}
            />
          </label>
          <label>
            <span>Price</span>
            <input
              type="text"
              onChange={(e) => setPrice(e.target.value)}
              value={price}
              title="Nöqtə ilə vergül yox M: 49.99"
            />
          </label>
          <label>
            <span>Sizes</span>
            <input
              type="text"
              onChange={(e) => setSizes(e.target.value)}
              value={sizes}
              title="Aralarında vergül qoymaqla. Sonuncuda qoyulmur. M:  S, M, Xl"
            />
          </label>
          <label>
            <span>External Url</span>
            <input
              type="text"
              onChange={(e) => setExternalUrl(e.target.value)}
              value={externalUrl}
              title="Hansı saytdan əlavə olunursa o link. M: https://www.zara.com/prd/204083765"
            />
          </label>
          <label>
            <span>Itemnote</span>
            <input
              type="text"
              onChange={(e) => setItemNote(e.target.value)}
              value={itemNote}
              title="Əgər əlavə qeyd varsa M Bir ölçü boyük almaq tovsiyə olunur"
            />
          </label>
          <label>
            <span>tags</span>
            <input
              type="text"
              onChange={(e) => setTags(e.target.value)}
              value={tags}
              title="Krasovka, Platya, Nyu balans"
            />
          </label>
          <label>
            <span>Material</span>
            <textarea
              type="text"
              onChange={(e) => setMaterial(e.target.value)}
              value={material}
            />
          </label>
          <label>
            <span>Detalis</span>
            <textarea
              onChange={(e) => setDetalis(e.target.value)}
              value={detalis}
            />
          </label>
          <label>
            <span>Size And Fit</span>
            <textarea
              onChange={(e) => setSizeAndFit(e.target.value)}
              value={sizeAndFit}
            />
          </label>
          <label>
            <span>Images</span>
            <textarea
              onChange={(e) => setImages(e.target.value)}
              value={images}
            />
          </label>
        </div>
        <div className="addPageWrapperOptional addPageWrapperBody">
          <div className="addPageFooter">
            <label title="Məhsul boyük və ağır deyilsə standart 10">
              <span>Delivery Fee (min 10)€</span>
              <input
                type="number"
                onChange={(e) => setDeliveryFee(e.target.value)}
                value={deliveryFee}
              />
            </label>
          </div>
          <div className="addPageFooter">
            <label title="Standart 16 Avro yəni ~ 30 AZN">
              <span>Profit more (min 15)€</span>
              <input
                onChange={(e) => setProfit(e.target.value)}
                value={profit}
              />
            </label>
          </div>
          <div className="addPageFooter" title="Saytda gorunsun?">
            <span>isPublished</span>
            <label className="switch switcherWrapper">
              <input
                type="checkbox"
                onChange={(e) => setIsPublished(!isPublished)}
                value={isPublished}
              />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="addPageFooter">
            <span>inStock</span>
            <label className="switch switcherWrapper">
              <input type="checkbox" onClick={() => setInStock(!inStock)} />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="addPageFooter">
            <label>
              <h4>
                Total:
                {Math.round(
                  (
                    Number(price) +
                    Number(deliveryFee) +
                    Number(profit)
                  ).toFixed(2) * aznToday
                )}
                ₼
              </h4>
            </label>
          </div>
        </div>
        <button
          type="submit"
          className="btn btnAdd btnLarge"
          onClick={handleAdd}
          disabled={!getIsFormValid()}
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default Add;
