import React, { useState, useEffect } from "react";
import "./products.css";
import { Link } from "react-router-dom";
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
const thead = [
  "SKU",
  "Image",
  "MC",
  "Sub Cat",
  "Alt Cat",
  "Brand",
  "Price",
  "Color",
  "Sizes",
  "Link",
  "inStock",
  "isPub.",
];
const Products = () => {
  const [products, setProducs] = useState([]);
  const [status, setSatus] = useState({});
  const [searchText, setSearchText] = useState("");
  const handleSelect = (e) => {
    let value = e.target.value;
    let id = e.target.id;

    setSatus({ id: id, value: value });
  };

  const handleApply = async (e) => {
    e.preventDefault();
    let _currentStatusFind = products.filter((x) => x.id === status.id);
    let currentStatus = _currentStatusFind[0][status.value]; // status from db
    let _statusName = status.value;
    let _statusValue = !currentStatus;
    const _update = { [_statusName]: _statusValue };
    if (status.value === "delete") {
      handleDelete(status.id);
    }
    try {
      const _currentCollectionRef = doc(db, "products", status.id);

      // Set the "capital" field of the city 'DC'
      await updateDoc(_currentCollectionRef, _update);
    } catch (error) {
      console.log(error.code);
    }
  };
  //Fetching data
  useEffect(() => {
    const unsub = onSnapshot(
      collection(db, "products"),
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setProducs(list);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
  }, []);

  //Handle Update
  // const handleEdit = (e,id) => {
  //   console.log(id,
  //     stat)

  // };
  // const handleApply = async (id, updated) => {
  //   console.log("first", updated);
  //   try {
  //     const docRef = doc(db, "brands", id);
  //     const data = {
  //       brandName: updated,
  //     };
  //     updateDoc(docRef, data);
  //     setShow(false);
  //   } catch (error) {
  //     console.log("Oops, Error happining during editing", +error);
  //   }
  // };
  // let itemMainImage = props?.images.split(",")[0].replace("?imwidth=156&", "");
  // itemMainImage = itemMainImage.replace("filter=packshot", "");
  const handleDelete = async (id) => {
    try {
      alert("Do you want to delete this item?");
      await deleteDoc(doc(db, "products", id));
    } catch (error) {
      console.log(error.code);
    }
  };
  const handleClick = (pr) => {
    window.open(`http://localhost:3000/item/${pr.externalUrl}`);
  };
  const keys = ["altCategory", "itemSKU", "brand", "tags"];

  const searchFn = (products) => {
    return products?.filter((item) =>
      keys.some((key) => item[key].toLowerCase().includes(searchText))
    );
  };

  console.log();

  return (
    <div className="productsPage page">
      <div className="categoreisPageTop">
        <h1 className="pageTitle">Products</h1>
        <div className="btnOptions">
          <Link to="/sizes" className="btn btnLarge btnAdd">
            Add a Size
          </Link>
          <Link to="/add" className="btn btnLarge btnAdd">
            Add a Product
          </Link>
        </div>
      </div>
      <div className="dataGrid prodWrap">
        <div className="filterWrapper ">
          <input
            type="text"
            value={searchText}
            placeholder="Product search"
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <table>
          <thead>
            <tr>
              {thead.map((e, index) => (
                <th key={index}>{e}</th>
              ))}
              <th colSpan={3}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {searchFn(products)?.map((pr) => (
              <tr key={pr.id} title={pr.id}>
                <td>{pr.itemSKU}</td>
                <td>
                  <img src={pr.images[0]} alt={pr.itemSKU} width="50px" />
                </td>
                <td>{pr.mainCategory && pr.mainCategory.charAt(0)}</td>
                <td>{pr.subCategory}</td>
                <td>{pr.altCategory}</td>
                <td>{pr.brand}</td>
                <td>{pr.grossPrice}</td>
                <td>{JSON.stringify(pr.colors.toString())}</td>
                <td className="sizesrow">{JSON.stringify(pr.sizes)}</td>
                <td>{pr.externalUrl}</td>
                <td>{pr.inStock ? "YES" : "NO"}</td>
                <td>{pr.isPublished ? "YES" : "NO"}</td>
                <td>
                  <select id={pr.id} onChange={handleSelect}>
                    <option></option>
                    <option value="inStock">Change Stock</option>
                    <option value="isPublished">Change Publish</option>
                    <option value="delete" style={{ color: "red" }}>
                      Delete this Item
                    </option>
                  </select>
                </td>
                <td>
                  <button
                    className="btn btnSmall btnEdit"
                    onClick={(pr) => handleApply(pr)}
                  >
                    Apply
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Products;
