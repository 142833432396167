import { useEffect, useState } from "react";
import "./brands.css";
import {
  addDoc,
  collection,
  doc,
  onSnapshot,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import Modal from "../../components/modal/Modal";
const Brands = () => {
  const [brandName, setBrandName] = useState("");
  const [err, setErr] = useState();
  const [success, setSuccess] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [show, setShow] = useState(false);
  //Input handling
  const handleChange = (e) => {
    e.preventDefault();
    setBrandName(e.target.value);
  };

  useEffect(() => {
    const unsub = onSnapshot(
      collection(db, "brands"),
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setBrandList(list);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
  }, []);

  const handleAdd = async (e) => {
    e.preventDefault();
    try {
      !(brandName === "") &&
        (await addDoc(collection(db, "brands"), {
          brandName,
        }));
      setSuccess(true);
      setBrandName("");
      setTimeout(() => {
        setSuccess(false);
      }, 1000);
    } catch (error) {
      setErr(error.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      try {
        await deleteDoc(doc(db, "brands", id));
        setBrandList(brandList.filter((item) => item.id !== id));
      } catch (error) {
        console.log(error);
      }
      // const res = await db.collection("cities").doc("DC").delete();
    } catch (error) {}
  };

  const handleEdit = async (id, updated) => {
    try {
      const docRef = doc(db, "brands", id);
      const data = {
        brandName: updated,
      };
      updateDoc(docRef, data);
      setShow(false);
    } catch (error) {
      console.log("Oops, Error happining during editing", +error);
    }
  };
  const showModal = async (b) => {
    setModalData(b);
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
  };
  return (
    <div className="brandsPage page">
      <div className="categoreisPageTop">
        <h1 className="pageTitle">Brands</h1>
        <div className="btnOptions">
          <input
            type="text"
            placeholder=""
            onChange={handleChange}
            value={brandName}
          />
          <button onClick={handleAdd}>Add a Brand</button>
          {success === true && <p className="successText">Brand added</p>}

          {err && <p>{err}</p>}
        </div>
      </div>
      <div className="dataGrid">
        <table className="tableList">
          <thead>
            <tr>
              <th>#</th>
              <th>Brand Name</th>
              <th>Size Guide</th>
              <th>How to measure</th>
              <th colSpan={2} style={{ textAlign: "center" }}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {brandList
              .sort((a, b) => a.brandName.localeCompare(b.brandName))
              .map((b) => (
                <tr key={b.id}>
                  <td>{brandList.indexOf(b) + 1}</td>
                  <td>{b.brandName}</td>
                  <td></td>
                  <td></td>
                  <td>
                    <button
                      className="btn btnSmall btnEdit"
                      style={{ width: "100%" }}
                      onClick={() => showModal(b)}
                    >
                      Edit
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btnSmall btnDelete"
                      style={{ width: "100%" }}
                      onClick={() => handleDelete(b.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Modal
        data={modalData}
        show={show}
        handleClose={hideModal}
        handleEdit={handleEdit}
      />
    </div>
  );
};

export default Brands;
