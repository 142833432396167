import React, { useState } from "react";
import "./settings.css";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { Link } from "react-router-dom";
const Settings = () => {
  const [data, setData] = useState({});
  const [currency, setCurrency] = useState(0);
  const handleWrapper = async (e) => {
    e.preventDefault();
    try {
      const docRef = await addDoc(collection(db, "containers"), data);
      console.log(docRef.id);
      // console.log("Document written with ID: ", docRef.id);
      // const docRef = await addDoc(collection(db, "cities"), {
      //   name: "Tokyo",
      //   country: "Japan"
      // });
      setData({});
    } catch (error) {
      console.log(error.code);
    }
  };
  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    setData({ ...data, [id]: value });
  };

  const handleCurrency = async (e) => {
    e.preventDefault();
    const data = { aznToEur: currency, timestamp: serverTimestamp() };
    try {
      await setDoc(doc(db, "currency", "DJQHJzB11oqhhpakfxjp"), data);
      setCurrency(0);
    } catch (error) {
      console.log(error.code, error.message);
    }
  };
  console.log(currency);

  return (
    <div className="settingsPage page">
      <h1 className="pageTitle">Settings </h1>
      <div className="wrappedContainer">
        <div className="wrapperHomePage ">
          <h4>Add new product container to home page</h4>
          <form>
            <label>
              <span>Container type</span>
              <select id="wrapperType" onChange={handleInput}>
                <option defaultValue={"type"}></option>
                <option value="main">Main</option>
                <option value="sub">Sub</option>
              </select>
            </label>
            <label>
              <span>Wrapper Title</span>
              <input
                type="text"
                placeholder=""
                id="wrapperTitle"
                onChange={handleInput}
              />
            </label>
            <label>
              <span>Wrapper Description</span>
              <input
                type="text"
                placeholder=""
                id="wrapperDescription"
                onChange={handleInput}
              />
            </label>
            <label>
              <span>
                Container <b>Background</b> Color
              </span>
              <input
                type="text"
                placeholder=""
                id="wrapperBackgroundColor"
                onChange={handleInput}
              />
            </label>
            <label>
              <span>
                Container <b>Text</b> Color
              </span>
              <input
                type="text"
                placeholder=""
                id="wrapperTextColor"
                onChange={handleInput}
              />
            </label>
            <label>
              <span>Container Background Image</span>
              <input
                type="text"
                placeholder=""
                id="wrapperBackgroundImg"
                onChange={handleInput}
              />
            </label>
            <div className="sliderQuerySection">
              <span>Query builder. Google Cloud Functions</span>
              <label>
                <span>Slider Fashion</span>
                <select id="wrapperFashion" onChange={handleInput}>
                  <option>Select fashion type</option>
                  <option value={"Women"}>Women</option>
                  <option value={"Men"}>Men</option>
                  <option value={"Kids"}>Kids</option>
                </select>
              </label>
              <label>
                <span>Slider Query</span>
                <select id="qProps" onChange={handleInput}>
                  <option defaultValue={"qprops"}>Select query props</option>
                  <option value={"brand"}>by Brand</option>
                  <option value={"altCategory"}>by Category</option>
                  <option value={"colors"}>colors</option>
                  <option value={"grossPrice"}>grossPrice</option>
                </select>
              </label>
              <label>
                <span>Value of query</span>
                <input
                  type="text"
                  placeholder=""
                  id="qValue"
                  onChange={handleInput}
                />
              </label>
            </div>

            <button className="btn btnLarge btnAdd" onClick={handleWrapper}>
              Add
            </button>
          </form>
        </div>
        <div className="wrappedHomePageList wrapperHomePage">
          <h4>Current Product containers</h4>
          <form>
            <label>
              <span>Currency of Today</span>
              <Link to={'https://www.cbar.az/'} className="cbarLink" target="_blank">Mezenneye bax</Link>
              <input
                type="text"
                placeholder=""
                id="wrapperTextColor"
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
              />
            </label>
            <button className="btn btnLarge btnAdd" onClick={handleCurrency}>
              Add
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Settings;
