import "./Order.css";
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { Link } from "react-router-dom";
const thead = [
  "SKU",
  "Image",
  "MC",
  "Sub Cat",
  "Alt Cat",
  "Brand",
  "Price",
  "Color",
  "Sizes",
  "Link",
  "inStock",
  "isPub.",
];
const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [unPaidCount, setUnPaidCount] = useState(0);

  useEffect(() => {
    const unsub = onSnapshot(
      collection(db, "orders"),
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setOrders(list);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
  }, []);
  console.log(orders);

  const handleApply = () => {};
  const handleSelect = () => {};
  return (
    <div className="salesPage page">
      <h1 className="pageTitle">Orders </h1>

      <div className="orderLists">
        {orders
          ?.sort(function (a, b) {
            return new Date(
              new Date(
                b.orderData?.orderDate.toString() -
                  a.orderData?.orderDate.toString()
              )
            );
          })
          .map((o) => (
            <div className="orderListItem" key={o.id}>
              <div className="orderItemHead">
                <div title="Sifariş kodu" className="sc-jnldDj LWmlU">
                  {o.orderData.orderNumber}
                </div>
                <div title="Sifariş tarixi" className="sc-jnldDj LWmlU">
                  {new Date(o.orderData.orderDate).toUTCString()}
                </div>
                <div title="Ödəniş" className="sc-jnldDj LWmlU">
                  <span className="sc-bZTxSs kkylOW">
                    {o?.orderData?.paymentStatus === false ? (
                      <span className="">Ödənilməyib</span>
                    ) : (
                      <b className="textSuc">Ödənilib</b>
                    )}
                  </span>
                </div>
                <div title="Çatdırılma Statusu" className="sc-jnldDj LWmlU">
                  <b>-</b>
                </div>
                <div title="Sifarişin cəm qiyməti" className="sc-jnldDj LWmlU">
                  199.95&nbsp;₼
                </div>
                <div className="btn btnSmall btnEdit">
                  <Link
                    className="acceptOrderLink"
                    to={`/orders/${o.id}`}
                    state={{
                      orderNo: o.orderData.orderNumber,
                      orderGID: o.id,
                      orderPStatus: o?.orderData?.paymentStatus,
                      orderDStatus: o?.orderData?.deliveryStatus,
                    }}
                  >
                    Sifarisi Qebul et
                  </Link>
                </div>
              </div>
              {o.orderItems.map((i) => (
                <div className="orderItemBody" key={i.itemSKU}>
                  <div className="sc-jnldDj LWmlU dds"></div>
                  <div className="sc-jnldDj LWmlU dds">{i.brand}</div>
                  <div className="sc-jnldDj LWmlU dds">{i.colors}</div>
                  <div className="sc-jnldDj LWmlU dds">{i.size}</div>
                </div>
              ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Orders;
