import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../../contex/AuthContext";
import BasicTimeline from "../../utiles/TimeLine";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";

import "./Order.css";
let today = new Date();
let date =
  today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
let time =
  today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
let dateTime = date + " " + time;
const EditOrder = () => {
  const { currentUser } = useContext(AuthContext);
  const current = new Date().toLocaleString();
  let location = useLocation();
  let catID = location.pathname.split("/")[2];
  const [order, setOrder] = useState({});
  const [itemLinkStr, setItemLinkStr] = useState("");
  // const [orderPaymentStatus, setOrderPaymentStatus] = useState(false);
  // const [orderDeliveryStatus, setOrderDeliveryStatus] = useState("");
  const [orderStatus, setOrderStatus] = useState({
    "orderData.paymentStatus": order?.orderData?.paymentStatus,
    "orderData.deliveryStatus": order?.orderData?.deliveryStatus,
  });
  const [data, setData] = useState({});

  //Fetching
  useEffect(() => {
    fetchOrder(catID);
  }, [catID]);

  const fetchOrder = async (id) => {
    try {
      const docRef = doc(db, "orders", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setOrder(docSnap.data());
      } else {
        console.log("No such document!");
      }
    } catch (error) {}
  };

  //Get item Link
  const handleLink = async (orderItemSKU) => {
    try {
      const q = query(
        collection(db, "userproducts"),
        where("itemSKU", "==", orderItemSKU)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        console.log(doc.data().externalUrl);
        setItemLinkStr(doc.data().externalUrl);
      });
    } catch (error) {}
  };

  //Change order Pyamnet and Delivery Status
  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    setData({ ...data, [id]: value });
    // setOrderStatus({...orderStatus, [id]: value});
  };

  const handleOrderStatus = async (e) => {
    e.preventDefault();

    try {
      // let _datas = {
      //   "orderData.deliveryStatus": orderDeliveryStatus,
      //   "orderData.paymentStatus": orderPay,
      // };
      const orderRef = doc(db, "orders", catID);
      await updateDoc(orderRef, data);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="salesPage page">
      <div className="categoreisPageTop">
        <h1 className="pageTitle">Edit Order </h1>
        <div className="btnOptions">
          Order:<b>{catID}</b>
        </div>
      </div>
      <div className="orderGeneralInfo">
        <div className="left">
          <table>
            <tbody>
              <tr>
                <th className="orthd">Order Code:</th>
                <td className="orthd">{location.state.orderNo}</td>
              </tr>
              <tr>
                <th className="orthd">Order Date:</th>
                <td className="orthd">
                  {new Date(order?.orderData?.orderDate).toUTCString()}
                </td>
              </tr>
              <tr>
                <th className="orthd">Payment:</th>
                <td className="orthd">
                  {order?.orderData?.paymentStatus === false ? (
                    <span className="">Ödənilməyib</span>
                  ) : (
                    <b className="textSuc">Ödənilib</b>
                  )}
                </td>
              </tr>
              <tr>
                <th className="orthd">Delivery Status</th>
                <td className="orthd">
                  {order?.orderData?.deliveryStatus === "-" ? (
                    <b>-</b>
                  ) : (
                    <b className="textSuc">{order.orderData?.deliveryStatus}</b>
                  )}
                </td>
              </tr>
              <tr>
                <th className="orthd">Total</th>
                <td className="orthd">{order.orderData?.total}</td>
              </tr>
            </tbody>
          </table>
          <div className="orderLists dataGrid w100">
            <div className="orderLists dataGrid">
              <table className="tableList">
                <thead>
                  <tr>
                    <th>Link</th>
                    <th>Item SKU</th>
                    <th>Brand</th>
                    <th>Olcu</th>
                    <th>Reng</th>
                  </tr>
                </thead>
                <tbody>
                  {order.orderItems?.map((item) => (
                    <tr key={item?.itemSKU}>
                      <td>
                        {itemLinkStr === "" ? (
                          <button onClick={() => handleLink(item.itemSKU)}>
                            Get Link
                          </button>
                        ) : (
                          <Link to={itemLinkStr} target="_blank">
                            Link
                          </Link>
                        )}
                      </td>
                      <td>{item.itemSKU}</td>
                      <td>{item.brand}</td>
                      <td>{item.colors}</td>
                      <td>{item.size}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="center"></div>
        <div className="right ">
          <div className="orderActionHead wrapperHomePage">
            <div className="whohandlethisorder ">
              <div className="handlerName"> {currentUser.displayName}</div>
              <div className="handlerdatelog">{current}</div>
            </div>
            <form className="orderForm">
              <label>
                <span>Change order Pyamnet Status</span>

                <select id="orderData.paymentStatus" onChange={handleInput}>
                  <option value={order.orderData?.paymentStatus}>{order.orderData?.paymentStatus}</option>
                  <option value="true">Ödənilib</option>
                  <option value="false">Ödənilməyib</option>
                </select>
              </label>
              <label>
                <span>Change order Delivery Status</span>
                <select id="orderData.deliveryStatus" onChange={handleInput}>
                  <option value={order.orderData?.deliveryStatus}>{order.orderData?.deliveryStatus}</option>
                  <option value="-">-</option>
                  <option value="Hazirlanir">Hazirlanir</option>
                  <option value="Göndərildi">Gonderildi</option>
                  <option value="Bakıya çatdırıldı">Bakıya çatdırıldı</option>
                  <option value="Tamamlandı">Tamamlandı</option>
                </select>
              </label>
              <button
                className="btn btnLarge updateStatusBtn"
                onClick={handleOrderStatus}
              >
                Update Status
              </button>
            </form>
          </div>
          <div className="orderLog">
            <BasicTimeline />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditOrder;
