import { addDoc, collection, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";

const Sales = () => {
  const [data, setData] = useState({});
const [sales,setSales] = useState([])
  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    setData({ ...data, [id]: value });
  };
  const handleOrder = async (e) => {
    e.preventDefault();
    try {
      const docRef = await addDoc(collection(db, "sales"), data);
      console.log(docRef.id);
      setData({});
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    const unsub = onSnapshot(
      collection(db, "sales"),
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setSales(list);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
  }, []);
  return (
    <div className="salesPage page">
      <h1 className="pageTitle">Sales </h1>

      <div className="salesWrapper wrapperHomePage">
        <form>
          <label>
            <span>Order No</span>
            <input type="text" onChange={handleInput} id="orderNo" />
          </label>
          <label>
            <span>Who</span>
            <input type="text" onChange={handleInput} id="who" />
          </label>
          <label>
            <span>External Track ID</span>
            <input type="text" onChange={handleInput} id="externaltrackId" />
          </label>
          <label>
            <span>External Track URI</span>
            <input type="text" onChange={handleInput} id="externalUri" />
          </label>
          <label>
            <span>Email</span>
            <input type="text" onChange={handleInput} id="mail" />
          </label>
          <button className="btn btnLarge btnAdd" onClick={handleOrder}>
            Add
          </button>
        </form>

        <div className="salesList">
          {sales?.map(s=><div key={s.orderNo}>{s.orderNo}</div>)}
        </div>
      </div>
    </div>
  );
};

export default Sales;
