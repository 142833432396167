export const words = {
  Outer: "Xarici  ",
  fabric: "parça",
  "material:": "materialı:",
  acrylic: "akril",
  cotton: "pambıq",
  "Fabric:": "Parça:",
  Knit: "Trikotaj",
  Care: "Baxım",
  "instructions:": "təlimatları:",
  wash: "yuma",
  hand: "əl",
  Hand: "əl",
  only: "Yalnız",
  Dry: "Quru",
  cleanable: "təmizlənə bilər",
  wool: 'yun',
  Lining: 'Astar',
  tumble: 'qurutma masini',
  "Upper":"Üst"
};
