import "./App.css";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
  Outlet,
  Navigate,
} from "react-router-dom";
import Siderbar from "./components/sidebar/Siderbar";
import Home from "./pages/Home/Home";
import Brands from "./pages/Brand/Brands";
import OrdersPage from "./pages/Orders/Orders";
import Categories from "./pages/Categories/Categories";
import Products from "./pages/Products/Products";
import Sales from "./pages/Sales/Sales";
import Add from "./pages/add/Add";
import Settings from "./pages/Settings/Settings";
import SizesComponent from "./components/sizes/Sizes";
import { useContext } from "react";
import { AuthContext } from "./contex/AuthContext";
import Login from "./pages/Auth/Login";
import EditOrder from "./pages/Orders/EditOrder";

const Layout = () => {
  return (
    <div className="App">
      <aside>
        <Siderbar />
      </aside>
      <main>
        <Outlet />
      </main>
    </div>
  );
};
function App() {
  const { currentUser } = useContext(AuthContext);

  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" />;
  };
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <RequireAuth>
          <Layout />
        </RequireAuth>
      ),
      children: [
        {
          path: "/",
          element: (
            <RequireAuth>
              <Home />
            </RequireAuth>
          ),
        },
        {
          path: "/brands",
          element: <Brands />,
        },
        {
          path: "/categories",
          element: <Categories />,
        },
        {
          path: "/orders",
          element: <OrdersPage />,
        },
        {
          path: "/orders/:id",
          element: <EditOrder />,
        },
        {
          path: "/products",
          element: <Products />,
        },
        {
          path: "/sales",
          element: <Sales />,
        },
        {
          path: "/add",
          element: <Add />,
        },
        {
          path: "/settings",
          element: <Settings />,
        },
        {
          path: "/sizes",
          element: <SizesComponent />,
        },
      ],
    },
    { path: "/login", element: <Login /> },
    { path: "*", element: <h1>Ooops<p>Something is wrong :/</p></h1> },

  ]);
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
