import { initializeApp } from "firebase/app";
import {getAuth,GoogleAuthProvider} from 'firebase/auth';
import {getFirestore} from 'firebase/firestore';
import { FacebookAuthProvider } from "firebase/auth";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "bogucci.firebaseapp.com",
  projectId: "bogucci",
  storageBucket: "bogucci.appspot.com",
  messagingSenderId: "8696599573",
  appId: process.env.REACT_APP_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth()
export const provider = new GoogleAuthProvider();
export const fb = new FacebookAuthProvider();