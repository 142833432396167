import React, { useState } from "react";
import "./modal.css";
const Modal = ({ data, handleClose, show, handleEdit }) => {
  const showHideClassName = show ? " display-block" : " display-none";
  const [updatedData, setUpdatedData] = useState(data.brandName );
  // console.log(updatedData)

  return (
    <div className={`modalWrapper ${showHideClassName}`}>
      <div className="modal">
        <div className="modalHead">
          <p>{data.id }</p>
          <button className="modalClose" onClick={handleClose}>
            <span>×</span>
          </button>
        </div>
        <div className="modalBody">
          <label>
            <span>{data.brandName }</span>
            <input
              type="text"
              placeholder={data.brandName || data.size}
              // value={updatedData}
              onChange={(e) => setUpdatedData(e.target.value)}
            />
          </label>
        </div>
        <div className="modalFooter">
          <button
            className="btn btnLarge btnAdd"
            onClick={() => handleEdit(data.id,updatedData)}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
