import { collection, getCountFromServer } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contex/AuthContext";
import { db } from "../../firebase";
import "./home.css";

const Home = () => {
  const [itemCount, setItemCount] = useState(0);
  const [brendCount, setBrendCount] = useState(0);

  const getStat = async () => {
    try {
      const coll = collection(db, "products");
      const snapshot = await getCountFromServer(coll);
      setItemCount(snapshot.data().count);
      const brands = collection(db, "brands");
      const brandList = await getCountFromServer(brands);
      setBrendCount(brandList.data().count);
    } catch (error) {}
  };
  useEffect(() => {
    getStat();

    return () => {
      getStat();
    };
  }, []);

  return (
    <div className="homePage page">
      <h1 className="pageTitle">Home </h1>

      <header>
        <Link to="/add">Add Item</Link>
        <Link to="/categories">Add Category</Link>
        <Link to="/brands">Add Brand</Link>
        <Link to="/settings">Add Slider</Link>
      </header>

      <main>
        <pre>
          <p> Admin v1.2 </p>
          <p>Change log</p>

          <p>Online/Offline offline funksiyasi - Offline olanda mehsul <b>yukleme</b></p>
          <p>Mehsulu silmek funksiyasi</p>
          <p>Settingde Query builder funksiyasi</p>
          <p>Hal hazirki Mehsul sayi - {itemCount}</p>
          <p>Hal hazirki Brend Sayi - {brendCount}</p>
        </pre>
      </main>
    </div>
  );
};

export default Home;
