import React, { useEffect, useState } from "react";
import "./categories.css";
import {
  addDoc,
  collection,
  doc,
  set,
  onSnapshot,
  deleteDoc,
  updateDoc,
  Timestamp,
  where,
  query,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase";
const CategoryList = () => {
  const [data, setData] = useState([]);
  const [mainCategory, setMainCategory] = useState("");
  const [categories, setCategories] = useState();
  const [subCategories, setSubCatgeories] = useState([]);
  // const [data, setData] = useState({
  //   fashion: "",
  //   subCategory: "",
  //   tags: "",
  // });
  // useEffect(() => {
  //   getUserPosts();
  // }, []);
  // const getUserPosts = async () => {
  //   let _posts = [];
  //   try {
  //     // Create a query against the collection.
  //     const q = query(
  //       collection(db, "categories"),
  //       where("main", "==", "Woman")
  //     );
  //     const querySnapshot = await getDocs(q);
  //     querySnapshot.forEach((doc) => {
  //       // _posts.push({ id: doc.id, ...doc.data().title });
  //       console.log(doc.id, ...doc.data().title);
  //     });
  //     // setSubCatgeories(_posts);
  //   } catch (error) {}
  // };

  // console.log(subCategories);
  const handleCategory = async (e) => {
    e.preventDefault();
    // const [fashion, subCategory, altCategory, title, translate, type, uri] =
    //   categories;
    console.log(categories);
    try {
      const docRef = await addDoc(collection(db, "categories"), {
        main: categories.fashion,
        subCategory: categories.subCategory,
        title: categories.title,
        translate: categories.translate,
        type: categories.type,
        uri: categories.uri,
      });
      console.log("Document written with ID: ", docRef.id);
    } catch (error) {}
    // const newData = {};

    // if (data.subCategory === "") {
    //   newData.fashion = data.fashion;
    //   newData.subCategory = data.tags;
    // } else {
    //   Object.assign(newData, data);
    // }

    // try {
    //   await addDoc(collection(db, "fashion"), {
    //     ...data,
    //   });
    // } catch (error) {}
  };
  //Fetching data
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let list = [];
    try {
      const querySnapshot = await getDocs(collection(db, "categories"));
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        list.push({ id: doc.id, ...doc.data() });
      });
      setData(list);
    } catch (error) {
      console.log(error);
    }
  };

  //Handleing form inputs
  const handleChange = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setCategories({ ...categories, [id]: value });
  };

  return (
    <div className="categoriesPage page">
      <div className="categoreisPageTop">
        <h1 className="pageTitle">Categories</h1>
        {/* <div className="btnOptions">
          <input
            type="text"
            placeholder="Main Category"
            onChange={handleChange}
          />
          <button onClick={handelCategory}>Add a Category</button>
        </div> */}
      </div>
      <div className="addWrapper addCatField" title="It`s looks you are not permission to edit this">
        <label className="add-ch add-CatItem">
          <span>Fashion</span>
          <select onChange={handleChange} id="fashion" >
            <option></option>
            <option>Women</option>
            <option>Men</option>
            <option>Kids</option>
          </select>
        </label>
        <label className="add-ch">
          <span>subCategories</span>
          <select onChange={handleChange} id="subCategory"  aria-label="You are not permission to edit this">
            <option></option>
            <option>Clothing</option>
            <option>Shoes</option>
            <option>Accessories</option>
            <option>Beauty & Care</option>
          </select>
        </label>
        <label className="add-ch">
          <span>Type</span>
          <select onChange={handleChange} id="type" >
            <option></option>
            <option>SubCategory</option>
            <option>AltCategory</option>
            <option>Field</option>
          </select>
        </label>
        <label className="add-ch">
          <span>Title</span>
          <input type="text" onChange={handleChange} id="title" />
        </label>
        <label className="add-ch">
          <span>Uri</span>
          <input type="text" onChange={handleChange} id="uri" />
        </label>
        <label className="add-ch">
          <span>Translate</span>
          <input type="text" onChange={handleChange} id="translate" />
        </label>
        <label className="add-ch">
          <button className="btn btnLarge btnAdd" onClick={handleCategory} >
            Add
          </button>
        </label>
      </div>
      <div className="dataGrid">
        <table className="tableList">
          <thead>
            <tr>
              <th>#</th>
              <th>Main categories</th>
              <th>Sub categories</th>
              <th>Alt Category </th>
              <th>Title</th>
              <th>Translate</th>

            </tr>
          </thead>
          <tbody>
            {data?.sort((a, b) => a.main - b.main).map((x,index) => (
              <tr key={x?.id}>
              <td>{index+1}</td>
                <td>{x.main}</td>
                <td>{x.subCategory}</td>
                <td>{x.title}</td>
                <td>{ x.altCategory || x.type}</td>
                <td>{x.translate}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CategoryList;
